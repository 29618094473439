import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from "@fortawesome/free-regular-svg-icons"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons"
import * as styles from "./blog-post.module.css"

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO title={post.frontmatter.title} description={post.excerpt}></SEO>
      <div>
        <h2>{post.frontmatter.title}</h2>
        <div className={styles.container}>
          <div>
            <FontAwesomeIcon
              className={styles.icon}
              icon={faCalendarAlt}
            ></FontAwesomeIcon>
            {post.frontmatter.date}
          </div>
          <div>
            <FontAwesomeIcon
              className={styles.icon}
              icon={faClock}
            ></FontAwesomeIcon>
            {post.timeToRead} min reading time
          </div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        author
      }
      excerpt
      timeToRead
    }
  }
`
